import { getMenuItemIcon } from 'markets-store/helpers/getMenuItemIcon'
import { getCustomSports } from 'markets-store/helpers/filterCustomMenu'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { TSportEvent } from 'markets-store'
import { getLink } from '@st/nuxt-markets-store/composables/useSportMenu'

interface Tab {
  id: string
  title: string
  icon: IconName
  numberId: number
  link: string
}

interface Options {
  byType?: 'sportId' | 'categoryId'
  scrollTop?: number
}

export function useBookmakerTabs({
  byType = 'sportId',
  scrollTop = 92,
}: Options = {}) {
  const route = useRoute()
  const router = useRouter()

  const selectedTabs = ref<Tab[]>([])
  const selectedTabsIds = ref<string[]>([])

  function scrollToTop() {
    nextTick(() => {
      window.scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth',
      })
    })
  }
  function handleSelectTabs(selectedItems: Tab[]) {
    selectedTabs.value = selectedItems
    const query = { ...route.query }
    query[byType] = selectedTabs.value.map((item) => item.id)
    router.replace({ query: { ...query } })
    setTimeout(() => scrollToTop(), 200)
  }
  function getTabFromRoute() {
    const tabFromRoute = Array.isArray(route.query[byType])
      ? route.query[byType]?.[0]
      : route.query[byType]

    if (tabFromRoute) {
      selectedTabsIds.value.push(tabFromRoute as string)
    }
  }
  getTabFromRoute()

  const isSelectedSomeTab = computed(() => selectedTabs.value.length > 0)

  return {
    handleSelectTabs,
    selectedTabsIds,
    isSelectedSomeTab,
  }
}

export function useSportTabs(
  events: Ref<TSportEvent[]>,
  { byType = 'sportId', scrollTop = 92 }: Options = {},
) {
  const customMenu = useCustomMenu()
  const sportTabs = computed<Tab[]>(() => {
    const menuSports = getCustomSports(customMenu.value, events.value)
    return menuSports.map((menuEntity) => ({
      id: menuEntity.entityId,
      title: menuEntity.name,
      icon: (getMenuItemIcon({ item: menuEntity, level: 0 })?.name ??
        'all-sports') as IconName,
      numberId: menuEntity.id,
      link: getLink(menuEntity),
    }))
  })

  const { handleSelectTabs, selectedTabsIds } = useBookmakerTabs({
    byType,
    scrollTop,
  })

  return {
    sportTabs,
    selectedTabsIds,
    handleSelectTabs,
  }
}
